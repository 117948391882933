$black                : #000000;
$white                : #ffffff;
$primary              : #440086;
$secondary            : #DC426F;
$pink                 : #DC426F;
$yellow               : #F1C400;
$silver               : #d9d9d9;
$gray-dark            : #555555;
$gray                 : #E5E0E6;
$gray-light           : #f2f2f2;
$violet               : #440099;
$violet-light         : #f2eef8;
$skyblue              : #00B5DD;
$skyblue-dark         : #0a91d5;
$skyblue-light        : #DCE4EC;
$violet-light         : #eeebfe;
$whatsapp-normal      : #00bb2d;
$whatsapp-hover       : #01e675;
$primary-text         : #333;
$primary-gdnt         : linear-gradient($primary, $secondary);
$primary-gdnt-right   : linear-gradient(to right, $primary, $secondary);