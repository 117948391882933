/* WEBFONTS */ 
@font-face {
    font-family: 'Gilroy-Regular';
    font-display: swap;
    src: url('../webfonts/Gilroy-Regular.woff2') format('woff2'), url('../webfonts/Gilroy-Regular.woff') format('woff'), url('../webfonts/Gilroy-Regular.ttf') format('truetype');
}


@font-face {
    font-family: 'Gilroy-SemiBold';
    font-display: swap;
    src: url('../webfonts/Gilroy-SemiBold.woff2') format('woff2'), url('../webfonts/Gilroy-SemiBold.woff') format('woff'), url('../webfonts/Gilroy-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Bold';
    font-display: swap;
    src: url('../webfonts/Gilroy-Bold.woff2') format('woff2'), url('../webfonts/Gilroy-Bold.woff') format('woff'), url('../webfonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-ExtraBold';
    font-display: swap;
    src: url('../webfonts/Gilroy-ExtraBold.woff2') format('woff2'), url('../webfonts/Gilroy-ExtraBold.woff') format('woff'), url('../webfonts/Gilroy-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Heavy';
    font-display: swap;
    src: url('../webfonts/Gilroy-Heavy.woff2') format('woff2'), url('../webfonts/Gilroy-Heavy.woff') format('woff'), url('../webfonts/Gilroy-Heavy.ttf') format('truetype');
}