// Template Forms 
.landing-template-theme-form {
    background-color: var(--violet);
    background-image: url(../../assets/images/fondo-fichas.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
 
    #formulario-colegios {
        .titulo-form {
            position: relative;
            font-family: 'Gilroy-ExtraBold', sans-serif;
            font-size: 2rem;
            background: $violet;
            background: -webkit-linear-gradient(to right, $pink 40%, $violet 60%);
            background: -moz-linear-gradient(to right, $pink 40%, $violet 60%);
            background: linear-gradient(to right, $pink 40%, $violet 60%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            
            @include media-breakpoint-up(sm) {
                font-size: 5rem;
            }
        }
    }
}

// TYP
#gracias {

    h2 {
        font-family: 'Gilroy-ExtraBold', sans-serif;
        font-size: 3rem;
        color: $yellow;
    }
    h3 {
        font-family: 'Gilroy-Regular', sans-serif;
    }
    .typ-content  {

        p {
            font-size: 1.2rem;
        }
    }
    a {
        background-color: $yellow;
        color: $violet;
        padding: 12px 30px;
        border-radius: 50px;
        margin: 0 auto;
        transition: all .35s;

        &:hover {
            transform: scale(1.1);
        }
    }
}