.wpcf7-spinner {
    display: block !important;
}
.wpcf7-radio {

    .wpcf7-list-item { 
        display: flex;
        margin-left: 0 !important;

        label {
            
            display: flex;
            
            .wpcf7-list-item-label {
                order: 2;
                margin-left: 10px;
            }
            input {
                order: 1;
            }
        }
    }
    .wpcf7-list-item:not(:last-child) {
        margin-bottom: 10px;
    }
}
.wpcf7-list-item { 
    margin-left: 0 !important;
}